import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ed40022"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-3 flex justify-content-between align-items-center" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReadMore = _resolveComponent("ReadMore")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSynced, (data, index) => {
      return (_openBlock(), _createElementBlock("li", { key: index }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", null, _toDisplayString(data.title), 1),
            _createElementVNode("h4", null, _toDisplayString(data.subtitle), 1),
            _createElementVNode("h5", null, _toDisplayString(data.date), 1)
          ]),
          (data.pictureURL)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: data.pictureURL,
                alt: data.title
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_ReadMore, {
          text: data.description
        }, null, 8, ["text"])
      ]))
    }), 128))
  ]))
}