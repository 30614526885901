import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-753f92e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.textSynced?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.formattedString), 1),
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.triggerReadMore && _ctx.triggerReadMore(...args)))
        }, _toDisplayString(_ctx.isReadMore ? "Moins" : "Plus"), 1)
      ]))
    : _createCommentVNode("", true)
}