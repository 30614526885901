import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24ad576e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-wrap justify-content-around" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Section = _resolveComponent("Section")!

  return (_openBlock(), _createBlock(_component_Section, {
    anchor: "hobbies",
    icon: "heart",
    title: "Loisirs"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hobbies, (hobby, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "flex flex-column justify-content-center",
            key: index
          }, [
            _createElementVNode("h3", _hoisted_2, _toDisplayString(hobby.title), 1),
            _createElementVNode("img", {
              class: "mb-3",
              src: hobby.pictureURL,
              alt: hobby.title
            }, null, 8, _hoisted_3)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}