import {
  getModule,
  Module,
  MutationAction,
  VuexModule,
} from "vuex-module-decorators";
import data from "../assets/data/hobbies.json";
import store from "@/store/Store";
import { IHobby } from "@/models/IHobby";

@Module({ dynamic: true, store, name: "HobbiesStore", namespaced: true })
class HobbiesStore extends VuexModule {
  hobbies: IHobby[] = [];

  @MutationAction
  async load(): Promise<{ hobbies: IHobby[] }> {
    return { hobbies: await Promise.resolve(data) };
  }
}

export default getModule(HobbiesStore);
