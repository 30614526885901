
import { Vue } from "vue-class-component";
import HobbiesStore from "@/store/HobbiesStore";
import { IHobby } from "@/models/IHobby";

export default class Hobbies extends Vue {
  beforeCreate(): void {
    HobbiesStore.load();
  }

  get hobbies(): IHobby[] {
    return HobbiesStore.hobbies;
  }
}
