import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimeLine = _resolveComponent("TimeLine")!
  const _component_Section = _resolveComponent("Section")!

  return (_openBlock(), _createBlock(_component_Section, {
    anchor: "jobs",
    icon: "briefcase",
    title: "Expériences"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_TimeLine, { data: _ctx.timelineData }, null, 8, ["data"])
    ]),
    _: 1
  }))
}