import {
  getModule,
  Module,
  MutationAction,
  VuexModule,
} from "vuex-module-decorators";
import data from "../assets/data/skills.json";
import store from "@/store/Store";
import { ISkill } from "@/models/ISkill";

@Module({ dynamic: true, store, name: "SkillsStore", namespaced: true })
class SkillsStore extends VuexModule {
  skills: ISkill[] = [];

  @MutationAction
  async load(): Promise<{ skills: ISkill[] }> {
    return { skills: await Promise.resolve(data) };
  }
}

export default getModule(SkillsStore);
