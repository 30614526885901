
import ProfileStore from "@/store/ProfileStore";
import { Vue } from "vue-class-component";
import { IProfile } from "@/models/IProfile";

export default class Profile extends Vue {
  readonly store = ProfileStore;

  beforeCreate(): void {
    ProfileStore.load();
  }

  get profile(): IProfile {
    return ProfileStore.profile;
  }
}
