
import { PropSync } from "vue-property-decorator";
import { Vue } from "vue-class-component";
import { IVersus } from "@/models/IVersus";

export default class VersusLine extends Vue {
  @PropSync("versus") versusSynced!: IVersus[];

  get versusShuffled(): IVersus[] {
    this.shuffle(this.versusSynced);
    return this.versusSynced;
  }

  get firstPercent(): number {
    return this.versusShuffled[0].score
      ? this.versusShuffled[0].score * 10
      : (10 - this.versusShuffled[1].score) * 10;
  }

  private shuffle(array: IVersus[]): void {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
}
