import {
  getModule,
  Module,
  MutationAction,
  VuexModule,
} from "vuex-module-decorators";
import data from "../assets/data/jobs.json";
import store from "@/store/Store";
import { IJob } from "@/models/IJob";

@Module({ dynamic: true, store, name: "JobsStore", namespaced: true })
class EducationsStore extends VuexModule {
  jobs: IJob[] = [];

  @MutationAction
  async load(): Promise<{ jobs: IJob[] }> {
    return { jobs: await Promise.resolve(data) };
  }
}

export default getModule(EducationsStore);
