import {
  getModule,
  Module,
  MutationAction,
  VuexModule,
} from "vuex-module-decorators";
import data from "../assets/data/educations.json";
import store from "@/store/Store";
import { IEducation } from "@/models/IEducation";

@Module({ dynamic: true, store, name: "EducationsStore", namespaced: true })
class EducationsStore extends VuexModule {
  educations: IEducation[] = [];

  @MutationAction
  async load(): Promise<{ educations: IEducation[] }> {
    return { educations: await Promise.resolve(data) };
  }
}

export default getModule(EducationsStore);
