import {
  getModule,
  Module,
  MutationAction,
  VuexModule,
} from "vuex-module-decorators";
import { IProfile } from "@/models/IProfile";
import data from "../assets/data/profile.json";
import store from "@/store/Store";

@Module({ dynamic: true, store, name: "ProfileStore", namespaced: true })
class ProfileStore extends VuexModule {
  profile: IProfile = {};

  @MutationAction
  async load(): Promise<{ profile: IProfile }> {
    return { profile: await Promise.resolve(data) };
  }

  get fullName(): string {
    return `${this.profile.firstname} ${this.profile.lastname}`;
  }
}

export default getModule(ProfileStore);
