import { createApp } from "vue";
import App from "./App.vue";
import store from "@/store/Store";
import "@fontsource/open-sans/800.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/300.css";
import mdiVue from "mdi-vue/v3";
import * as mdi from "@mdi/js";
import Section from "@/components/Section.vue";
import VersusLine from "@/components/VersusLine.vue";
import TimeLine from "@/components/TimeLine.vue";
import ReadMore from "@/components/ReadMore.vue";

createApp(App)
  .use(store)
  .use(mdiVue, {
    icons: { ...mdi },
  })
  .component("Section", Section)
  .component("VersusLine", VersusLine)
  .component("TimeLine", TimeLine)
  .component("TimeLine", TimeLine)
  .component("ReadMore", ReadMore)
  .mount("#app");
