
import { Options, Vue } from "vue-class-component";
import Menu from "@/components/Menu.vue";
import Main from "@/components/Main.vue";
import Footer from "@/components/Footer.vue";

@Options({
  components: {
    Menu,
    Main,
    Footer,
  },
})
export default class App extends Vue {}
