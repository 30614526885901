
import { Vue } from "vue-class-component";
import { PropSync } from "vue-property-decorator";

export default class TimeLine extends Vue {
  @PropSync("text") textSynced: string;
  @PropSync("maxChars", { default: 130 }) maxCharsSynced: number;

  isReadMore = false;

  get formattedString(): string {
    if (!this.isReadMore && this.textSynced.length > this.maxCharsSynced) {
      return this.textSynced.substring(0, this.maxCharsSynced) + "...";
    }

    return this.textSynced;
  }

  triggerReadMore($event: MouseEvent): void {
    $event.preventDefault();
    this.isReadMore = !this.isReadMore;
  }
}
