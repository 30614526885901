
import { Vue } from "vue-class-component";
import EducationsStore from "@/store/EducationsStore";
import { TimeLineData } from "@/components/TimeLine.vue";

export default class Educations extends Vue {
  beforeCreate(): void {
    EducationsStore.load();
  }

  get timelineData(): TimeLineData[] {
    return EducationsStore.educations.map((education) => ({
      title: education.entity,
      subtitle: education.title,
      date: education.dates,
      pictureURL: education.pictureURL,
    }));
  }
}
