import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid container" }
const _hoisted_2 = { class: "lg:m-0 xl:w-18rem md:col-12 fixed xl:p-2 md:p-0 z-5" }
const _hoisted_3 = { class: "lg:mb-0 xl:col-9 xl:col-offset-3 col-12 xl:mt-0 md:mt-7 sm:mt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Main = _resolveComponent("Main")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Menu)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Main)
      ])
    ]),
    _createVNode(_component_Footer)
  ], 64))
}