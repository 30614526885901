
import { Vue } from "vue-class-component";
import JobsStore from "@/store/JobsStore";
import { TimeLineData } from "@/components/TimeLine.vue";

export default class Jobs extends Vue {
  beforeCreate(): void {
    JobsStore.load();
  }

  get timelineData(): TimeLineData[] {
    return JobsStore.jobs.map((job) => ({
      title: job.enterprise,
      subtitle: job.title,
      date: job.dates,
      description: job.description,
      pictureURL: job.pictureURL,
    }));
  }
}
