
import { Vue } from "vue-class-component";
import { ISkill } from "@/models/ISkill";
import SkillsStore from "@/store/SkillsStore";

export default class Skills extends Vue {
  beforeCreate(): void {
    SkillsStore.load();
  }

  get skills(): ISkill[] {
    return SkillsStore.skills;
  }
}
