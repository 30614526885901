
import { Vue } from "vue-class-component";
import { IProfile } from "@/models/IProfile";
import ProfileStore from "@/store/ProfileStore";

export default class Menu extends Vue {
  size = 29;
  readonly store = ProfileStore;

  get profile(): IProfile {
    return ProfileStore.profile;
  }
}
