import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "scroll" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Profile = _resolveComponent("Profile")!
  const _component_Skills = _resolveComponent("Skills")!
  const _component_Jobs = _resolveComponent("Jobs")!
  const _component_Educations = _resolveComponent("Educations")!
  const _component_Hobbies = _resolveComponent("Hobbies")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Profile),
    _createVNode(_component_Skills),
    _createVNode(_component_Jobs),
    _createVNode(_component_Educations),
    _createVNode(_component_Hobbies)
  ]))
}