
import { PropSync } from "vue-property-decorator";
import { Vue } from "vue-class-component";

export interface TimeLineData {
  title?: string;
  subtitle?: string;
  date?: string;
  description?: string;
  pictureURL?: string;
}

export default class TimeLine extends Vue {
  @PropSync("data") dataSynced!: TimeLineData[];
}
