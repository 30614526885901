import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15ed77ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header flex justify-content-between" }
const _hoisted_2 = { class: "first" }
const _hoisted_3 = {
  key: 0,
  class: "second"
}
const _hoisted_4 = { class: "outer-line" }
const _hoisted_5 = { class: "first icon" }
const _hoisted_6 = { class: "gauge" }
const _hoisted_7 = { class: "second icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.versusShuffled[0].title), 1),
      (_ctx.versusShuffled[1])
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.versusShuffled[1].title), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_mdicon, {
          size: 30,
          name: _ctx.versusShuffled[0].icon
        }, null, 8, ["name"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          style: _normalizeStyle({ width: _ctx.firstPercent + '%' }),
          class: "first"
        }, null, 4),
        _createElementVNode("div", {
          style: _normalizeStyle({ width: 101 - _ctx.firstPercent + '%' }),
          class: "second"
        }, null, 4)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_mdicon, {
          size: 30,
          name: _ctx.versusShuffled[1].icon
        }, null, 8, ["name"])
      ])
    ])
  ], 64))
}