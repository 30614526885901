
import { Options, Vue } from "vue-class-component";
import Profile from "@/components/Profile.vue";
import Skills from "@/components/Skills.vue";
import Jobs from "@/components/Jobs.vue";
import Educations from "@/components/Educations.vue";
import Hobbies from "@/components/Hobbies.vue";

@Options({
  components: {
    Profile,
    Skills,
    Jobs,
    Educations,
    Hobbies,
  },
})
export default class Main extends Vue {}
